.Testimonials {
  margin-bottom: 5rem;
  .container {
    position: relative;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    overflow: hidden;
    .background {
      position: absolute;
      background-image: url("../../assets/images/testimonial_bg.jpg");
      background-repeat: no-repeat;
      height: 100%;
      width: 100%;
      opacity: 0.25;
      background-position: center;
      background-attachment: fixed !important;
    }
    .content {
      margin: auto;
      margin-top: 3rem;
      .testimonials_container {
        margin: auto;
        .heading {
          text-align: center;
          .highlight {
            margin: auto;
          }
          .heading_text {
            text-align: center;
            font-weight: 500;
            margin-bottom: 3rem;
            letter-spacing: 2px;
            font-size: 2.5rem;
          }
        }
        .swiper {
          height: fit-content;
        }
        .swiper-slide {
          background: transparent;
          background-color: transparent;
        }
        .swiper_container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: white;
          img {
            height: 70px;
            width: 70px;
            border-radius: 50%;
            margin: 1rem auto;
            border: 4px solid #09aff4;
          }
          .name {
            font-size: 1.5rem;
          }
          .post {
            font-size: 1rem;
          }
          .comment {
            margin: 0.8rem;
            width: 80%;
            font-style: italic;
            font-size: 1.1rem;
          }
        }
      }
    }
  }
}
@media (min-width: 576px) {
  .Testimonials {
    .container {
      .content {
        max-width: 540px;
      }
    }
  }
}
@media (min-width: 768px) {
  .Testimonials {
    .container {
      padding-top: 5rem;
      padding-bottom: 5rem;
      .content {
        margin-top: 5rem;
        .testimonials_container {
          width: 83.3333%;
          .heading {
            .heading_text {
              font-size: 3rem;
            }
          }
          .swiper_container {
            .comment {
              width: 65%;
              font-size: 1.5rem;
            }
          }
        }
        max-width: 720px;
      }
    }
  }
}
@media (min-width: 992px) {
  .Testimonials {
    .container {
      .content {
        max-width: 960px;
      }
    }
  }
}
@media (min-width: 1200px) {
  .Testimonials {
    .container {
      .content {
        max-width: 1140px;
      }
    }
  }
}
@media (min-width: 1400px) {
  .Testimonials {
    .container {
      .content {
        max-width: 1320px;
      }
    }
  }
}
