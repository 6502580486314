.AboutUs {
  .container {
    position: relative;
    color: white;
    a {
      text-decoration: none;
      color: inherit;
    }
    .content {
      margin: auto;
      padding: 10px;
      // margin-top: 2rem;
      .heading {
        font-weight: 500;
      }
      .grid_container {
        margin: 5rem auto;
      }
      .grid {
        margin-top: 2rem;
        .image,
        .para {
          width: 100%;
          height: 350px;
        }
        .image {
          display: flex;
          justify-content: center;
          margin-bottom: 2rem;

          img {
            object-position: center;
            height: 100%;
            width: 100%;
            object-fit: contain;
            margin: auto;
            // object-fit: cover;

            // width: 100%;
          }
        }
        .para {
          font-size: 1.2rem;
          min-height: 350px;
          height: 100%;
          text-align: justify;
        }
      }
    }
  }
}

@media (min-width: 576px) {
  .AboutUs {
    .container {
      .content {
        max-width: 540px;
      }
    }
  }
}
@media (min-width: 768px) {
  .AboutUs {
    .container {
      .content {
        max-width: 720px;
        .grid {
          .image {
            margin-bottom: 3rem;
          }
        }
      }
    }
  }
}
@media (min-width: 992px) {
  .AboutUs {
    .container {
      .content {
        max-width: 960px;
        .grid {
          display: flex;
          justify-content: space-between;
          align-items: center;
          image {
            margin-bottom: 0;
          }
          .image,
          .para {
            width: 49%;
          }
        }
        .grid_container:nth-child(odd) .grid {
          flex-direction: row-reverse;
        }
      }
    }
  }
}
@media (min-width: 1200px) {
  .AboutUs {
    .container {
      .content {
        max-width: 1140px;
      }
    }
  }
}
@media (min-width: 1400px) {
  .AboutUs {
    .container {
      .content {
        max-width: 1320px;
      }
    }
  }
}
