$portfolio_image_bottom_height: 70px;
.Portfolio {
  .container {
    .content {
      margin: 1.5rem auto 0 auto;
      .text_content {
        padding: 10px;

        .heading {
          font-size: 2.5rem;
          font-weight: 500;
        }
        .style_btn {
          min-width: 100px;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          padding-right: 0rem;
          &:hover {
            i {
              left: 30%;
            }
          }
          span {
            display: inline-block;
            height: 2rem;
            position: relative;
            width: 50px;
            i {
              top: 2px;
              left: 0%;
              position: absolute;
              margin-top: 7px !important;
              margin-left: 5px;
              transition: all 0.5s ease;
            }
          }
        }

        .options {
          margin-top: 3rem;
          display: flex;
          color: white;
          justify-content: center;
          font-size: 1.3rem;
          font-weight: 400;
          flex-wrap: wrap;
          .option {
            padding: 0.8rem;
            flex-shrink: 0;
            cursor: pointer;
            transition: all 0.3s ease;
            position: relative;

            &::before {
              content: "";
              height: 5px;
              width: 5px;
              border-radius: 50%;
              background: #09aff4;
              position: absolute;
              left: -0px;
              top: 50%;
              transform: translateY(-50%);
              opacity: 0;
              transition: all 0.3s ease;
            }
            &:hover {
              color: #09aff4;
            }
            &:hover::before {
              opacity: 1;
            }

            // &:first-child {
            //   padding-left: 0.4rem;
            // }
          }
        }
      }
      .images_container {
        transition: all 0.5s ease;
        .image_boxes {
          display: grid;
          grid-template-columns: 1fr;
          gap: 1rem;
          transition: all 0.5s ease;
          .image_box {
            position: relative;
            height: 450px;
            cursor: pointer;
            overflow: hidden;
            border-radius: 5px;
            transition: all 0.5s ease;
            &:hover img {
              scale: 1.05;
            }
            &:hover .bottom_content {
              bottom: 0;
            }
            &:hover .bottom_content-btn {
              left: 0;
            }
            img {
              height: 100%;
              width: 100%;
              object-position: center;
              object-fit: cover;
            }
            img,
            .bottom_content {
              transition: all 0.5s ease;
            }
            .bottom_content {
              position: absolute;
              bottom: -$portfolio_image_bottom_height;
              width: 100%;
              height: $portfolio_image_bottom_height;
              padding-left: 100px;
              background: rgba(0, 0, 0, 0.6);
              color: white;
              font-size: 1.5rem;
              font-weight: 500;
              display: flex;
              justify-content: center;
              flex-direction: column;
            }
            .bottom_content-btn {
              position: absolute;
              width: 55px;
              height: $portfolio_image_bottom_height;
              left: -$portfolio_image_bottom_height;
              bottom: 0;
              transition: all 0.5s ease 0.3s;
              color: white;
              background: #0f8ecc;
              border: none;
              display: grid;
              place-content: center;
              img {
                height: 20px;
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 576px) {
  .Portfolio {
    .container {
      .content {
        max-width: 540px;

        .text_content {
          .style_btn {
            padding: 0.6rem 1.5rem;
          }
        }
        .images_container {
          .image_boxes {
            .image_box {
              // max-width: 532px;
              // margin: auto;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 768px) {
  .Portfolio {
    .container {
      .content {
        max-width: 720px;
        .text_content {
          .heading {
            font-size: 3rem;
          }
          .options {
            justify-content: flex-start;
          }
        }
        .images_container {
          .image_boxes {
            display: grid;
            grid-template-columns: 1fr 1fr;
          }
        }
      }
    }
  }
}
@media (min-width: 992px) {
  .Portfolio {
    .container {
      .content {
        max-width: 960px;
        .text_content {
          .flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
        .images_container {
          .image_boxes {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
          }
        }
      }
    }
  }
}
@media (min-width: 1200px) {
  .Portfolio {
    .container {
      .content {
        max-width: 1140px;
      }
    }
  }
}
@media (min-width: 1400px) {
  .Portfolio {
    .container {
      .content {
        max-width: 1320px;
      }
    }
  }
}
