.Gallery .container {
  position: relative;
  color: white;
}
.Gallery .container a {
  text-decoration: none;
  color: inherit;
}
.Gallery .container .content {
  margin: auto;
}
.Gallery .gallery_container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin: 2rem auto;
}
.Gallery .gallaries {
  overflow: hidden;
  height: 500px;
}
.Gallery .gallaries .swiper {
  width: 80%;
  height: 100%;
}
.Gallery .gallaries .swiper .gallery_image {
  height: 100%;
}
.Gallery .gallaries .swiper .gallery_image img {
  -o-object-fit: cover;
     object-fit: cover;
}

@media (min-width: 576px) {
  .Gallery .container .content {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .Gallery .container .content {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .Gallery .container .content {
    max-width: 960px;
  }
  .Gallery .container .content .gallery_container {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 1200px) {
  .Gallery .container .content {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .Gallery .container .content {
    max-width: 1320px;
  }
}/*# sourceMappingURL=Gallery.css.map */