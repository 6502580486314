.ContactUs a {
  text-decoration: none;
  color: inherit;
}
.ContactUs .container {
  position: relative;
}
.ContactUs .container .content {
  color: white;
  padding: 10px;
  padding-bottom: 2rem;
  position: relative;
  margin: auto;
}
.ContactUs .find-us {
  height: calc(100vh - 100px);
  margin-top: 2rem;
  margin-bottom: 0rem;
}
.ContactUs .find-us .find-us-title {
  font-size: 2.6rem;
  font-weight: 500;
  text-align: center;
}
.ContactUs .find-us .details {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.ContactUs .find-us .details iframe {
  width: 100%;
  height: 55vh;
  margin-top: 5rem;
}
.ContactUs .questions .form_container label .title {
  color: white;
  font-weight: 500;
}
.ContactUs .questions .form_container label .title.required::after {
  content: "*";
  color: red;
  margin-left: 0.5rem;
  font-size: 1.5rem;
}
.ContactUs .questions .form_container label input,
.ContactUs .questions .form_container label textarea {
  width: 100%;
  outline: none;
  border: 1px solid rgba(255, 255, 255, 0.25);
  background: none;
  color: white;
  font-weight: 400;
  font-size: 1rem;
  padding: 1rem;
}
.ContactUs .questions .form_container label textarea {
  min-height: 10rem;
}
.ContactUs .questions .form_container label:focus-within .title {
  color: #09aff4;
  transition: all 0.5s ease;
}
.ContactUs .questions .form_container label input:focus,
.ContactUs .questions .form_container label textarea:focus {
  border: 1px solid #09aff4;
  transition: all 0.5s ease;
}
.ContactUs .questions .form_container button {
  margin-top: 2.5rem;
}
.ContactUs .questions .form_container h1 {
  text-align: center;
  font-size: 3rem;
  font-weight: 500;
  margin-bottom: 3rem;
  letter-spacing: 1px;
}
.ContactUs div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-color: red !important;
}

@media (min-width: 576px) {
  .ContactUs .container .content {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .ContactUs .container .content {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .ContactUs .container .content {
    max-width: 960px;
  }
  .ContactUs .questions .name-email {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
}
@media (min-width: 1200px) {
  .ContactUs .container .content {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .ContactUs .container .content {
    max-width: 1320px;
  }
}/*# sourceMappingURL=ContactUs.css.map */