.Overview {
  height: fit-content;
  width: 100%;
  height: fit-content;
  background-size: cover;
  margin-top: 5rem;
  margin-bottom: 5rem;
  .overview_content {
    width: 100%;
    height: fit-content;
    position: relative;
    position: relative;
    // margin: auto;

    .overview_bg {
      width: 100%;
      z-index: -1;
      position: absolute;
      width: 100%;
      height: 100%;
      background: url("../../assets/images/overview_background.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      opacity: 0.2;
      background-position: center;
    }
    .overview_container {
      // padding-left: 0.75rem;
      // padding-right: 0.75rem;
      padding: 1rem 3rem;
      width: 100%;
      height: fit-content;
      margin: auto;
      .box_container {
        height: fit-content;
        padding: 1rem 3rem;
        margin-top: 4rem;
        width: 100%;
        background-color: rgba(6, 9, 34, 0.25);
        border: 1px solid #09aef473;
        border-top: 0.25rem solid #09aff4;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        will-change: transform;
        &:last-child {
          margin-bottom: 5rem;
        }
        .box {
          // height: 150px;
          width: 100%;
          display: flex;
          // align-items: center;
          justify-content: space-between;
          flex-direction: column;
          align-items: center;
          padding: 1.2rem;
          pointer-events: none;
          .icon {
            height: 3rem;
            width: 3rem;
            img {
              height: 100%;
              width: 100%;
            }
          }
          .number {
            color: white;
            font-size: 2rem;
            font-weight: 600;
          }
          .text {
            font-size: 1.3rem;
            color: white;
            font-weight: 400;
            text-align: center;
          }
        }
      }
    }
  }
}
@media (min-width: 576px) {
  .Overview {
    .overview_content {
      .overview_container {
        max-width: 540px;
      }
    }
  }
}
@media (min-width: 768px) {
  .Overview {
    .overview_content {
      .overview_container {
        max-width: 730px;
        // border: 1px solid red;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        padding: 4.5rem 0;
        .box_container {
          width: 45%;
          flex-grow: 0;
          flex-shrink: 0;
          margin: 1rem;
          &:last-child {
            margin-bottom: 0;
          }
          .box {
            .number {
              font-size: 2.9rem;
              margin-top: 0.2rem;
            }
            .text {
              font-size: 1.25rem;
              margin-top: 0.2rem;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 992px) {
  .Overview {
    .overview_content {
      .overview_container {
        max-width: 960px;
        flex-wrap: nowrap;
        .box_container {
          width: 22%;
          padding: 2.5rem 2rem;
          .box {
            width: 100%;
            padding: 0rem;

            .text {
              width: 100%;
              font-size: 1.25rem;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 1200px) {
  .Overview {
    .overview_content {
      .overview_container {
        max-width: 1140px;
        .box_container {
          padding-top: 2.5rem;
          padding-bottom: 2.5rem;
        }
      }
    }
  }
}
@media (min-width: 1400px) {
  .Overview {
    .overview_content {
      .overview_container {
        max-width: 1320px;
      }
    }
  }
}
