.WorkWithUsPage .swiper_container {
  margin: 5rem auto;
}
.WorkWithUsPage .content .heading {
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 2.4rem;
  margin-bottom: 2rem;
}
.WorkWithUsPage .container {
  position: relative;
  color: white;
}
.WorkWithUsPage .container a {
  text-decoration: none;
  color: inherit;
}
.WorkWithUsPage .container .content {
  margin: auto;
}
.WorkWithUsPage .questions {
  margin-top: 2rem;
  margin-bottom: 5rem;
}
.WorkWithUsPage .questions .form_container label .title {
  color: white;
  font-weight: 500;
}
.WorkWithUsPage .questions .form_container label .title.required::after {
  content: "*";
  color: red;
  margin-left: 0.5rem;
  font-size: 1.5rem;
}
.WorkWithUsPage .questions .form_container label input,
.WorkWithUsPage .questions .form_container label textarea {
  width: 100%;
  outline: none;
  border: 1px solid rgba(255, 255, 255, 0.25);
  background: none;
  color: white;
  font-weight: 400;
  font-size: 1rem;
  padding: 1rem;
}
.WorkWithUsPage .questions .form_container label .input_container {
  height: -moz-fit-content;
  height: fit-content;
  display: grid;
  place-content: center;
  border: 1px solid rgba(255, 255, 255, 0.25);
}
.WorkWithUsPage .questions .form_container label .input_container input {
  border: none;
}
.WorkWithUsPage .questions .form_container label textarea {
  min-height: 10rem;
}
.WorkWithUsPage .questions .form_container label:focus-within .title {
  color: #09aff4;
  transition: all 0.5s ease;
}
.WorkWithUsPage .questions .form_container label input:focus,
.WorkWithUsPage .questions .form_container label textarea:focus,
.WorkWithUsPage .questions .form_container label .input_container:focus {
  border: 1px solid #09aff4;
  transition: all 0.5s ease;
}
.WorkWithUsPage .questions .form_container label .input_container:focus input,
.WorkWithUsPage .questions .form_container label .input_container input:focus {
  border: none !important;
}
.WorkWithUsPage .questions .form_container button {
  margin-top: 2.5rem;
}
.WorkWithUsPage .questions .form_container h1 {
  text-align: center;
  font-size: 3rem;
  font-weight: 500;
  margin-bottom: 3rem;
  letter-spacing: 1px;
}

@media (min-width: 576px) {
  .WorkWithUsPage .container .content {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .WorkWithUsPage .container .content {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .WorkWithUsPage .container .content {
    max-width: 960px;
  }
  .WorkWithUsPage .questions .name-email {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
}
@media (min-width: 1200px) {
  .WorkWithUsPage .container .content {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .WorkWithUsPage .container .content {
    max-width: 1320px;
  }
}/*# sourceMappingURL=WorkWithUs.css.map */