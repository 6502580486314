.right {
  position: relative;
}
.image_container {
  position: absolute;
}
.image_container:hover {
  animation: bounceAnimation 1s infinite alternate ease-in-out;
}

.swiper {
  width: 100%;
  height: 100px;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: grab;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
}

@keyframes bounceAnimation {
  from {
    top: 0px;
  }
  to {
    top: 20px;
  }
}
