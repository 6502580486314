.Homepage {
  height: 130vh;
  background: #080f2f;
  position: relative;
  padding-top: 1px;

  .overlay {
    opacity: 0.2;
    background-image: url(../assets/images/section_one_background.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 0;
  }

  .section_one {
    width: 100%;
    height: 100%;
    // background-color: red;
    z-index: 2;
    position: absolute;
    display: flex;
    // overflow: hidden;
    flex-direction: column;
    padding: 0 1rem;
    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -1px;
      width: 100%;
      height: 100%;
      clip-path: polygon(50% 100%, 100% 0%, 100% 100%, 0 100%);
      background: rgba(72, 32, 167, 0.5);
      z-index: -1;
    }
    .content,
    .right {
      width: 100%;
      height: 50%;
      position: relative;
    }
    .content {
      margin: 8.5rem auto 0 auto;
      .heading {
        span::before {
          background-image: url(../assets/images/curly.png);
        }
      }
      .socials {
        display: flex;
        margin-bottom: 1rem;
        margin-top: 1rem;
        .icons {
          height: 40px;
          width: 40px;
          display: grid;
          color: white;
          cursor: pointer;
          margin-right: 4px;
          font-size: 1.1rem;
          position: relative;
          a {
            color: white;
          }
          i {
            position: absolute;
            transition: all 0.2s ease;
            top: 0;
          }
          &:hover i {
            color: #09aff4;
            top: -20%;
          }
        }
      }

      .paragraph {
        color: white;
        opacity: 0.75;
        margin-top: 2rem;
        width: 80%;
      }

      .btn_container {
        height: fit-content;
        width: 100%;
        margin-top: 2rem;
      }
      @media (min-width: 981px) {
        .paragraph {
          margin-top: 0rem;
        }
      }
    }
    .right {
      .image_container {
        height: 100%;
        // border: 1px solid yellow;
        width: 100%;
        display: grid;
        justify-content: center;
        // align-items: center;
        img {
          margin-top: -2.5rem;
          height: 320px;
          // border: 1px solid red;
          will-change: transform;
        }
      }
    }
    @media (min-width: 576px) {
      .content {
        max-width: 520px;
        .heading {
          font-size: 3rem;
        }
      }
    }
    @media (min-width: 768px) {
      .content {
        max-width: 700px;
      }
      .right {
        .image_container {
          img {
            margin-top: 2rem;
            height: 700px;
          }
        }
      }
    }
    @media (min-width: 981px) {
      .content,
      .right {
        width: 50%;
        height: 50vh;
        max-width: 650px;

        // margin: 0;
      }
    }
  }
  @media (min-width: 981px) {
    .section_one {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding-left: 2.5rem;
      .content {
        // border: 1px solid red;
        margin: 0;
        .heading {
          margin-bottom: 1rem;
        }
      }
      .right {
        margin-right: 2.5rem;
        // border: 1px solid red;
      }
    }
  }
  .section_two {
  }

  @media (max-width: 981px) {
    padding-top: 1px;

    .section_one::after {
      clip-path: polygon(0 100%, 100% 50%, 100% 100%);
    }
    .section_one .content {
      // margin: 7.5rem auto 0 auto;
    }
  }
}
@media (min-width: 375px) {
  .Homepage {
    .section_one {
      .right {
        .image_container {
          img {
            height: 350px;
            margin-top: -1rem;
          }
        }
      }
    }
  }
}
@media (min-width: 500px) {
  .Homepage {
    .section_one {
      .right {
        .image_container {
          img {
            height: 480px;
            margin-top: -5rem;
          }
        }
      }
    }
  }
}

@media (min-width: 981px) {
  .Homepage {
    height: 100vh;
    .section_one {
      margin-top: 5rem;
      &::after {
        bottom: 80px;
      }
      .content {
        .paragraph {
          width: 70%;
          margin-top: 2rem;
          font-size: 1.13rem;
        }
        .btn_container {
          button:first-child {
            padding: 0.75rem 2rem;
          }
        }
      }
      .right {
        .image_container {
          img {
            height: 450px;
            margin-top: 2rem;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1100px) {
  .Homepage {
    height: 100vh;
    .section_one {
      .content,
      .right {
        margin-top: -16rem;
      }
      .content {
        .heading {
          font-size: 2.3rem;
        }
        .paragraph {
          font-size: 1rem;
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .Homepage {
    height: 100vh;
    .section_one {
      .content,
      .right {
        margin-top: -16rem;
      }
      .content {
        .heading {
          font-size: 3.5rem;
        }
        .paragraph {
          font-size: 1.2rem;
          margin-top: 2rem;
          width: 100%;
        }
        .btn_container {
          button:first-child {
            padding: 0.8rem 2.75rem;
          }
        }
      }
      .right {
        .image_container {
          img {
            height: 560px;
            margin-top: -5.5rem;
          }
        }
      }
    }
  }
}
@media (min-width: 1400px) {
  .Homepage {
    .section_one {
      .content,
      .right {
        margin-top: -6rem;
      }
    }
  }
}
