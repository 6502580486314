.Gallery {
  .container {
    position: relative;
    color: white;
    a {
      text-decoration: none;
      color: inherit;
    }
    .content {
      margin: auto;
    }
  }
  .gallery_container {
    display: grid;
    grid-template-columns: 1fr;
    // border: 1px solid red;
    gap: 20px;
    margin: 2rem auto;
  }
  .gallaries {
    // border: 1px solid red;
    overflow: hidden;
    // width: 95%;
    height: 500px;
    .swiper {
      width: 80%;
      height: 100%;
      .gallery_image {
        // display: grid;
        // border: 1px solid red;
        height: 100%;
        img {
          object-fit: cover;
          // border: 1px solid red;
        }
        // place-content: center;
      }
    }
  }
}
@media (min-width: 576px) {
  .Gallery {
    .container {
      .content {
        max-width: 540px;
      }
    }
  }
}
@media (min-width: 768px) {
  .Gallery {
    .container {
      .content {
        max-width: 720px;
      }
    }
  }
}
@media (min-width: 992px) {
  .Gallery {
    .container {
      .content {
        max-width: 960px;
        .gallery_container {
          grid-template-columns: 1fr 1fr;
        }
      }
    }
  }
}
@media (min-width: 1200px) {
  .Gallery {
    .container {
      .content {
        max-width: 1140px;
      }
    }
  }
}
@media (min-width: 1400px) {
  .Gallery {
    .container {
      .content {
        max-width: 1320px;
      }
    }
  }
}
