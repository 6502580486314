$input_border_color: rgba(255, 255, 255, 0.25);

.WorkWithUsPage {
  .swiper_container {
    margin: 5rem auto;
    padding-left: 10px;
    padding-right: 10px;
  }
  .content .heading {
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 2.4rem;
    margin-bottom: 2rem;
  }
  .container {
    position: relative;
    color: white;
    a {
      text-decoration: none;
      color: inherit;
    }
    .content {
      margin: auto;
    }
  }
  .questions {
    margin-top: 2rem;
    margin-bottom: 5rem;
    padding-left: 10px;
    padding-right: 10px;
    .form_container {
      label {
        .title {
          color: white;
          font-weight: 500;
        }
        .title.required::after {
          content: "*";
          color: red;
          margin-left: 0.5rem;
          font-size: 1.5rem;
        }
        input,
        textarea {
          width: 100%;
          outline: none;
          border: 1px solid $input_border_color;
          background: none;
          color: white;
          font-weight: 400;
          font-size: 1rem;
          padding: 1rem;
        }
        .input_container {
          height: fit-content;
          display: grid;
          place-content: center;
          border: 1px solid $input_border_color;
          input {
            border: none;
          }
        }
        textarea {
          min-height: 10rem;
        }

        &:focus-within .title {
          color: #09aff4;
          transition: all 0.5s ease;
        }
        input:focus,
        textarea:focus,
        .input_container:focus {
          border: 1px solid #09aff4;
          transition: all 0.5s ease;
        }
        .input_container:focus input,
        .input_container input:focus {
          border: none !important;
        }
      }
      button {
        margin-top: 2.5rem;
      }
      h1 {
        text-align: center;
        font-size: 3rem;
        font-weight: 500;
        margin-bottom: 3rem;
        letter-spacing: 1px;
      }
    }
  }
}

@media (min-width: 576px) {
  .WorkWithUsPage {
    .container {
      .content {
        max-width: 540px;
      }
    }
  }
}
@media (min-width: 768px) {
  .WorkWithUsPage {
    .container {
      .content {
        max-width: 720px;
      }
    }
  }
}
@media (min-width: 992px) {
  .WorkWithUsPage {
    .container {
      .content {
        max-width: 960px;
      }
    }
    .questions {
      .name-email {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
      }
    }
  }
}
@media (min-width: 1200px) {
  .WorkWithUsPage {
    .container {
      .content {
        max-width: 1140px;
      }
    }
  }
}
@media (min-width: 1400px) {
  .WorkWithUsPage {
    .container {
      .content {
        max-width: 1320px;
      }
    }
  }
}
