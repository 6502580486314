.Portfolio .container .content {
  margin: 1.5rem auto 0 auto;
}
.Portfolio .container .content .text_content {
  padding: 10px;
}
.Portfolio .container .content .text_content .heading {
  font-size: 2.5rem;
  font-weight: 500;
}
.Portfolio .container .content .text_content .style_btn {
  min-width: 100px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 0rem;
}
.Portfolio .container .content .text_content .style_btn:hover i {
  left: 30%;
}
.Portfolio .container .content .text_content .style_btn span {
  display: inline-block;
  height: 2rem;
  position: relative;
  width: 50px;
}
.Portfolio .container .content .text_content .style_btn span i {
  top: 2px;
  left: 0%;
  position: absolute;
  margin-top: 7px !important;
  margin-left: 5px;
  transition: all 0.5s ease;
}
.Portfolio .container .content .text_content .options {
  margin-top: 3rem;
  display: flex;
  color: white;
  justify-content: center;
  font-size: 1.3rem;
  font-weight: 400;
  flex-wrap: wrap;
}
.Portfolio .container .content .text_content .options .option {
  padding: 0.8rem;
  flex-shrink: 0;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
}
.Portfolio .container .content .text_content .options .option::before {
  content: "";
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background: #09aff4;
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: all 0.3s ease;
}
.Portfolio .container .content .text_content .options .option:hover {
  color: #09aff4;
}
.Portfolio .container .content .text_content .options .option:hover::before {
  opacity: 1;
}
.Portfolio .container .content .images_container {
  transition: all 0.5s ease;
}
.Portfolio .container .content .images_container .image_boxes {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  transition: all 0.5s ease;
}
.Portfolio .container .content .images_container .image_boxes .image_box {
  position: relative;
  height: 450px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 5px;
  transition: all 0.5s ease;
}
.Portfolio .container .content .images_container .image_boxes .image_box:hover img {
  scale: 1.05;
}
.Portfolio .container .content .images_container .image_boxes .image_box:hover .bottom_content {
  bottom: 0;
}
.Portfolio .container .content .images_container .image_boxes .image_box:hover .bottom_content-btn {
  left: 0;
}
.Portfolio .container .content .images_container .image_boxes .image_box img {
  height: 100%;
  width: 100%;
  -o-object-position: center;
     object-position: center;
  -o-object-fit: cover;
     object-fit: cover;
}
.Portfolio .container .content .images_container .image_boxes .image_box img,
.Portfolio .container .content .images_container .image_boxes .image_box .bottom_content {
  transition: all 0.5s ease;
}
.Portfolio .container .content .images_container .image_boxes .image_box .bottom_content {
  position: absolute;
  bottom: -70px;
  width: 100%;
  height: 70px;
  padding-left: 100px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  font-size: 1.5rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.Portfolio .container .content .images_container .image_boxes .image_box .bottom_content-btn {
  position: absolute;
  width: 55px;
  height: 70px;
  left: -70px;
  bottom: 0;
  transition: all 0.5s ease 0.3s;
  color: white;
  background: #0f8ecc;
  border: none;
  display: grid;
  place-content: center;
}
.Portfolio .container .content .images_container .image_boxes .image_box .bottom_content-btn img {
  height: 20px;
}

@media (min-width: 576px) {
  .Portfolio .container .content {
    max-width: 540px;
  }
  .Portfolio .container .content .text_content .style_btn {
    padding: 0.6rem 1.5rem;
  }
}
@media (min-width: 768px) {
  .Portfolio .container .content {
    max-width: 720px;
  }
  .Portfolio .container .content .text_content .heading {
    font-size: 3rem;
  }
  .Portfolio .container .content .text_content .options {
    justify-content: flex-start;
  }
  .Portfolio .container .content .images_container .image_boxes {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 992px) {
  .Portfolio .container .content {
    max-width: 960px;
  }
  .Portfolio .container .content .text_content .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .Portfolio .container .content .images_container .image_boxes {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (min-width: 1200px) {
  .Portfolio .container .content {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .Portfolio .container .content {
    max-width: 1320px;
  }
}/*# sourceMappingURL=Portfolio.css.map */