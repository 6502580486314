.ContactUs {
  $background_color: #060922;
  $primary_color: #09aff4;
  $input_border_color: rgba(255, 255, 255, 0.25);
  a {
    text-decoration: none;
    color: inherit;
  }
  // margin-top: 100px;

  .container {
    position: relative;

    .content {
      color: white;
      padding: 10px;
      padding-bottom: 2rem;
      position: relative;
      margin: auto;
    }
  }
  .find-us {
    height: calc(100vh - 100px);
    margin-top: 2rem;
    margin-bottom: 0rem;
    .find-us-title {
      font-size: 2.6rem;
      font-weight: 500;
      text-align: center;
    }
    .details {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      iframe {
        width: 100%;
        height: 55vh;
        margin-top: 5rem;
      }
    }
  }
  .questions {
    .form_container {
      label {
        .title {
          color: white;
          font-weight: 500;
        }
        .title.required::after {
          content: "*";
          color: red;
          margin-left: 0.5rem;
          font-size: 1.5rem;
        }
        input,
        textarea {
          width: 100%;
          outline: none;
          border: 1px solid $input_border_color;
          background: none;
          color: white;
          font-weight: 400;
          font-size: 1rem;
          padding: 1rem;
        }
        textarea {
          min-height: 10rem;
        }
        &:focus-within .title {
          color: #09aff4;
          transition: all 0.5s ease;
        }
        input:focus,
        textarea:focus {
          border: 1px solid #09aff4;
          transition: all 0.5s ease;
        }
      }
      button {
        margin-top: 2.5rem;
      }
      h1 {
        text-align: center;
        font-size: 3rem;
        font-weight: 500;
        margin-bottom: 3rem;
        letter-spacing: 1px;
      }
    }
  }
  div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
    background-color: red !important;
  }
}

@media (min-width: 576px) {
  .ContactUs {
    .container {
      .content {
        max-width: 540px;
      }
    }
  }
}
@media (min-width: 768px) {
  .ContactUs {
    .container {
      .content {
        max-width: 720px;
      }
    }
  }
}
@media (min-width: 992px) {
  .ContactUs {
    .container {
      .content {
        max-width: 960px;
      }
    }
    .questions {
      .name-email {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
      }
    }
  }
}
@media (min-width: 1200px) {
  .ContactUs {
    .container {
      .content {
        max-width: 1140px;
      }
    }
  }
}
@media (min-width: 1400px) {
  .ContactUs {
    .container {
      .content {
        max-width: 1320px;
      }
    }
  }
}
