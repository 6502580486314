.Navbar {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9;
  height: 90px;
  vertical-align: center;
  transition: all 0.5s ease;
}
.Navbar .menu-navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
}
.Navbar .logo-img {
  height: 30px;
}
.Navbar .menu-wrapper {
  margin-right: auto;
  margin-left: auto;
}
.Navbar .menu-item {
  position: relative;
  padding: 0 1rem;
}
.Navbar .responsive_overlay {
  height: 0;
  width: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.Navbar .has-sub-menu > .sub-menu {
  list-style: none;
  position: absolute;
  top: 100%;
  width: 300px;
  left: 0;
  margin: 0;
  padding: 1rem 1.5rem;
  transform: translateY(4rem);
  visibility: hidden;
  opacity: 0;
  z-index: 15000;
  background-color: #09aff4;
  transition: all 0.3s ease-in-out 0s;
}
.Navbar .has-sub-menu:hover > .sub-menu {
  visibility: visible;
  opacity: 1;
  transform: translateY(2rem);
}
.Navbar .has-sub-menu > .sub-menu .sub-menu-item {
  padding: 0;
}
.Navbar .has-sub-menu > .sub-menu .sub-menu-link {
  line-height: unset;
  color: white;
  padding: 1rem;
  padding-left: 0;
  text-transform: capitalize;
}
.Navbar .has-sub-menu > .sub-menu .sub-menu-link {
  line-height: unset;
  color: white;
  padding: 1rem;
  padding-left: 0;
}
.Navbar .menu-link {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-decoration: none !important;
  color: inherit;
  text-transform: capitalize;
  font-size: 1rem;
  font-weight: 500;
  line-height: 90px;
  padding: 0;
  transition: all 0.3s ease-in-out 0s;
  color: white;
}
.Navbar .menu-link i {
  margin-left: 2px;
}
.Navbar .menu-link.active,
.Navbar .menu-link:hover {
  color: #09aff4;
}
.Navbar .menu-link::before {
  content: "";
  height: 4px;
  width: 4px;
  border-radius: 50%;
  left: -10px;
  position: absolute;
  background-color: #09aff4;
  opacity: 0;
  transition: all 0.5s ease;
}
.Navbar .menu-link:hover::before,
.Navbar .menu-link.active::before {
  opacity: 1;
}
.Navbar .links-list {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}
.Navbar .header-search-btn {
  color: white;
  font-size: 1.5rem;
}
@media (max-width: 576px) {
  .Navbar .container {
    max-width: 540px;
  }
}
@media (max-width: 768px) {
  .Navbar .container {
    max-width: 720px;
  }
}
@media (min-width: 1200px) {
  .Navbar .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .Navbar .container {
    max-width: 1320px;
  }
}
.Navbar .container {
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 1200px) {
  .Navbar {
    background: #09aff4;
    padding: 0px 3rem;
    display: flex;
    align-items: center;
  }
  .Navbar .menu-item {
    font-size: 2.2rem;
  }
  .Navbar .menu-link {
    padding: 0;
    line-height: 0;
  }
  .Navbar .menu-link::before {
    background-color: white;
  }
  .Navbar .menu-link:hover {
    color: white;
  }
  .Navbar .responsive_overlay {
    height: calc(100vh - 100px);
    width: 100vw;
    top: 100px;
    left: -110%;
    z-index: -1;
  }
  .Navbar .menu-wrapper {
    position: absolute;
    left: -100%;
    top: 90px;
    background-color: #09aff4;
    height: 100vh;
    width: 50vw;
    max-width: 400px;
  }
  .Navbar .menu-wrapper .active {
    color: white;
  }
  .Navbar .menu-wrapper .sub-menu {
    padding: 0 2rem;
    overflow: hidden;
    height: 0;
  }
  .Navbar .menu-wrapper .links-list {
    flex-direction: column;
  }
  .Navbar .menu-wrapper .links-list .sub-menu {
    position: static;
    transform: translateY(0);
    background: none;
    margin-left: -1rem;
  }
  .Navbar .controls-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .Navbar .controls-box .menu-toggler {
    height: -moz-fit-content;
    height: fit-content;
    position: relative;
    height: 30px;
    width: 30px;
    cursor: pointer;
  }
  .Navbar .controls-box .menu-toggler span {
    width: 30px;
    height: 2px;
    background-color: white;
    display: block;
    margin: 5px auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .Navbar .controls-box .menu-toggler span:nth-child(1) {
    top: 0%;
    rotate: 0deg;
  }
  .Navbar .controls-box .menu-toggler span:nth-child(2) {
    top: 33%;
  }
  .Navbar .controls-box .menu-toggler span:nth-child(3) {
    top: 66%;
  }
}
@media only screen and (max-width: 576px) and (min-width: 300px) {
  .Navbar {
    padding: 0px 1rem;
  }
}
.highlight {
  position: relative;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 1px;
  text-align: left;
  text-transform: uppercase;
  display: block;
  width: -moz-max-content;
  width: max-content;
  margin-bottom: 0.3rem;
  margin-left: 0.3rem;
  padding-left: 10px;
  padding-right: 10px;
  color: #09aff4;
  height: -moz-fit-content;
  height: fit-content;
}
.highlight::before, .highlight::after {
  content: "";
  height: 6px;
  width: 6px;
  background: #09aff4;
  position: absolute;
  top: 50%;
  border-radius: 50%;
  transform: translateY(-50%);
}
.highlight::before {
  left: 0;
}
.highlight::after {
  right: 0;
}

.heading {
  font-size: 2rem;
  color: white;
}

.paragraph {
  color: white;
  opacity: 0.75;
  font-size: 1.1rem;
  line-height: 1.8rem;
  font-weight: 400;
}

.heading {
  padding-left: 5px;
  font-size: 1.95rem;
  font-weight: 700;
  color: white;
  position: relative;
}
.heading .stripes {
  position: absolute;
  width: 150px;
  height: 150px;
  opacity: 0.25;
  z-index: -1;
  left: 0;
  top: 0;
  transform: translate(-25%, -25%);
  border-radius: 50%;
  background-image: repeating-linear-gradient(-55deg, transparent 0.9px, #09aff4 1.25px, #09aff4 3px, transparent 4px, transparent 8px);
}
.heading span {
  color: #09aff4;
  display: inline-block;
  position: relative;
}
.heading span::before {
  content: "";
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  top: 90%;
  width: 100%;
  height: 100%;
}

.Navbar.scrolled {
  background-color: #09aff4;
}
.Navbar.scrolled .menu-link.active,
.Navbar.scrolled .menu-link:hover {
  color: white;
}
.Navbar.scrolled .menu-link:hover::before,
.Navbar.scrolled .menu-link.active::before {
  background: white;
}

.swiper_img_container {
  display: grid;
  place-content: center;
  height: 100%;
  width: 100%;
}
.swiper_img_container img {
  height: 90%;
  -o-object-fit: contain;
     object-fit: contain;
  filter: grayscale(100%); /* Start as black and white */
  transition: filter 0.5s ease; /* Smooth transition effect */
}
.swiper_img_container:hover img {
  filter: grayscale(0%); /* On hover, remove grayscale to make it colorful */
}

.page_header .background,
.page_header .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.page_header .background {
  background-repeat: no-repeat;
  background-image: url("../assets/images/section_one_background.jpg");
  background-attachment: fixed !important;
  background-size: cover;
  background-position: center;
}
.page_header .overlay {
  opacity: 0.75;
  background: #060922;
}
.page_header .content {
  height: 60vh;
}
.page_header .page_details {
  position: relative;
  z-index: 2;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.page_header .page_details .title {
  font-size: 2rem;
}
.page_header .page_details .breadcrumbs {
  font-weight: 500;
}
.page_header .page_details .breadcrumbs span {
  opacity: 0.8;
}

@media (min-width: 576px) {
  .page_header .page_details .title {
    font-size: 3rem;
  }
}
@media (min-width: 1200px) {
  .page_header .page_details .title {
    font-size: 4rem;
  }
}/*# sourceMappingURL=Pages.css.map */