.Services {
  margin-top: 135vh;
  min-height: 100vh;
}
.Services .content {
  max-width: 540px;
  margin: auto;
  padding: 10px;
}
.Services .content .headline {
  position: relative;
}
.Services .content .heading {
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 2.4rem;
}
.Services .content .paragraph {
  width: 80%;
}
.Services .content .style_btn {
  margin-top: 1rem;
  padding-left: 2rem;
}
.Services .content .style_btn:hover i {
  left: 30%;
}
.Services .content .style_btn span {
  display: inline-block;
  height: 2rem;
  position: relative;
  width: 50px;
}
.Services .content .style_btn span i {
  top: 2px;
  left: 0%;
  position: absolute;
  margin-top: 7px !important;
  margin-left: 5px;
  transition: all 0.5s ease;
}
.Services .grid .box {
  width: 100%;
  min-height: 300px;
  border: 1px solid rgba(9, 174, 244, 0.5019607843);
  border-radius: 10px;
  padding: 30px;
  margin-top: 2rem;
  position: relative;
  overflow: hidden;
  opacity: 1;
}
.Services .grid .box:hover .redirect_btn {
  color: white;
  transition: all 0.5s ease;
}
.Services .grid .box .before {
  height: 100%;
  width: 100%;
  background: #09aef4;
  left: -100%;
  top: 0;
  position: absolute;
  content: "";
  z-index: -1;
}
.Services .grid .box .icon {
  height: 60px;
  width: 60px;
  display: grid;
  place-content: center;
  font-size: 3rem;
  border-radius: 5px;
}
.Services .grid .box .icon img {
  height: 50px;
}
.Services .grid .box .title {
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 1px;
  color: white;
  margin-top: 1rem;
}
.Services .grid .box .paragraph {
  width: 100%;
  margin-top: 1rem;
}
.Services .grid .box .redirect_btn {
  color: #09aef4;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  font-weight: 600;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 1rem;
}
.Services .grid .box .redirect_btn span {
  display: inline-block;
  height: 2rem;
  position: relative;
  width: 50px;
  margin-left: 0.5rem;
}
.Services .grid .box .redirect_btn span i {
  top: 2px;
  left: 0%;
  position: absolute;
  margin-top: 7px !important;
  margin-left: 5px;
  transition: all 0.5s ease;
}
.Services .grid .box .redirect_btn:hover i {
  left: 30%;
}

@media (min-width: 768px) {
  .Services .content {
    max-width: 720px;
  }
  .Services .content .grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .Services .content .grid .box {
    width: calc(50% - 10px);
  }
  .Services .content .grid .box .paragraph {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .Services {
    margin-top: 105vh;
  }
  .Services .content {
    max-width: 960px;
  }
  .Services .content .grid .box {
    width: calc(33% - 20px);
  }
  .Services .content .headline {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0;
  }
  .Services .content .style_btn {
    flex-grow: 0;
    white-space: nowrap;
  }
}
@media (min-width: 1200px) {
  .Services .content {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .Services .content {
    max-width: 1320px;
  }
}/*# sourceMappingURL=Services.css.map */