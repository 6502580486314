* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Jost", sans-serif;
}
html,
body,
.App {
  height: 100%;
  width: 100%;
  background: #060922;
}

.black {
  width: 100vw;
  height: 100vh;
  background-color: #09aff4;
  position: absolute;
  z-index: 2;
}
.black.one {
  left: 25%;
}

.black.two {
  right: 25%;
}

.loading {
  overflow: hidden;
  position: relative;
  height: 100vh;
  width: 100vw;
  background: #080f2f;
  opacity: 1;
}

.loading_number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  font-size: 3rem;
  font-weight: 800;
  color: white;
  padding-bottom: 3rem;
}
.style_btn {
  position: relative;
  overflow: hidden;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none !important;
  text-align: center;
  text-transform: capitalize;
  letter-spacing: 1px;
  outline: none;
  border: 2px solid #09aff4;
  border-radius: 0.25rem;
  border-bottom-left-radius: 1rem;
  border-top-right-radius: 1rem;
  padding: 0.75rem 1.5rem;
  font-size: 1.1rem;
  font-weight: 500;
  box-shadow: 0px 3px 10px -5px rgba(#09aff4, 0.15);
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  z-index: 0;
  background: none;
  color: #09aff4;
  min-width: 200px;
}

.play_btn {
  background: #09aff4;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  border: none;
  margin-left: 2rem;
  color: white;
  position: relative;
  box-shadow: 0px 3px 10px -5px rgba(#09aff4, 0.15);
  cursor: pointer;
  transition: all 0.3s ease-in-out 0s;
}
.play_btn::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: inherit;
  animation: anim-pulses infinite 2s linear;
  z-index: -1;
}

.play_btn::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: inherit;
  animation: anim-pulses infinite 2s linear;
  z-index: -1;
  animation-delay: 0.75s;
}
@keyframes anim-pulses {
  0% {
    transform: scale3d(0.9, 0.9, 1);
    opacity: 0.5;
  }
  50% {
    transform: scale3d(1.2, 1.2, 1);
    opacity: 0.75;
  }
  100% {
    transform: scale3d(1.4, 1.4, 1);
    opacity: 0;
  }
}

.style_btn:hover,
.style_btn.invert {
  background: #09aff4;
  color: white;
}
.style_btn.invert:hover {
  background: transparent;
  color: #09aff4;
}
.play_btn:hover {
  background: white;
  color: #09aff4;
}
a {
  text-decoration: none;
}
