.SectionTwo {
  height: 100vh;
  width: 100%;
  max-width: 1400px;
  // margin-top: 135vh;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap-reverse;
  height: fit-content;
  .left,
  .right {
    height: 50%;
    width: 100%;
  }
  .left {
    padding: 20px;
    .heading {
      font-weight: 400;
      letter-spacing: 2px;
    }
    span::before {
      background-image: url(../../assets/images/stroke.png);
      stroke: red;
      stroke-width: 0.5rem;
      fill: transparent;
      top: 50%;
    }
    .paragraph {
      margin-top: 30px;
    }
    .list_items {
      height: fit-content;
      width: 100%;
      .list_item {
        color: white;
        margin-top: 2rem;

        .icon {
          background: #062241;
          width: fit-content;
          height: 55px;
          width: 55px;
          display: grid;
          place-content: center;
          font-size: 1.6rem;
          color: #09aff4;
          border-radius: 5px;
        }
        h3 {
          font-size: 1.3rem;
          text-transform: capitalize;
          font-weight: 500;
          margin: 10px 0;
        }
        .paragraph {
          margin-top: 0;
          line-height: 1.5rem;
          font-size: 1rem;
          width: 70%;
        }
      }
    }
    .btn_container {
      margin-top: 2rem;
    }
  }
  .right {
    display: grid;
    place-content: center;
    padding: 30px;
    height: 100vh; // 01/02/2024
    position: relative;
    .image_container {
      display: grid;
      place-content: center;
      height: 100%;
      width: 100%;
      margin: auto;
      position: absolute;
      img {
        height: 320px;
      }
    }
  }
}
@media (min-width: 550px) {
  .SectionTwo {
    .right {
      .image_container {
        img {
          height: 500px;
          width: 500px;
        }
      }
    }
    .left {
      padding: 50px;
    }
  }
}
@media (min-width: 768px) {
  .SectionTwo {
    .right {
      height: 100vh;
      .image_container {
        img {
          height: 650px;
          width: 650px;
        }
      }
    }
    .left {
      padding: 30px;
      padding-top: 0px;
      margin-top: -30px;
      .list_items {
        .list_item {
          margin-top: 4rem;
          display: flex;
          max-width: 600px;
          div {
            margin-top: -12px;
            margin-left: 1rem;
            margin h3 {
              margin: 0 0;
            }
          }
          .paragraph {
            width: 90%;
          }
        }
      }
      .btn_container {
        margin-top: 5rem;
      }
    }
  }
}

@media (min-width: 980px) {
  .SectionTwo {
    // margin-top: 100vh;

    .left,
    .right {
      width: 50%;
    }
    .left {
      padding: 15px;
      .heading {
        font-size: 3rem;
        font-weight: 500;
      }
      .list_items {
        .list_item {
          .paragraph {
            width: 50%;
          }
        }
      }
    }
    .right {
      .image_container {
        margin-top: -8rem;
        img {
          height: 450px;
          width: 450px;
        }
      }
    }
  }
}
@media (min-width: 1024px) {
  .SectionTwo {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
// @media only screen and (min-width: 1000px) and (max-width: 1100px) {
//   .SectionTwo {
//     // margin-top: 110vh;
//   }
// }

@media (min-width: 1200px) {
  .SectionTwo {
    // margin-top: 100vh;

    align-items: center;
    justify-content: center;
    .left {
      padding-left: 40px;
      .list_items {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 5px;
        grid-row-gap: 0px;
        .list_item {
          .icon {
            flex-shrink: 0;
          }
          .paragraph {
            width: 100%;
          }
        }
      }
    }
    .right {
      .image_container {
        margin-top: 0rem;
      }
    }
  }
}
@media (min-width: 1400px) {
  .SectionTwo {
    padding-left: 0rem;
    padding-right: 0rem;
    .right {
      .image_container {
        img {
          height: 600px;
          width: 600px;
        }
      }
    }
  }
}
