.SectionTwo {
  height: 100vh;
  width: 100%;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap-reverse;
  height: -moz-fit-content;
  height: fit-content;
}
.SectionTwo .left,
.SectionTwo .right {
  height: 50%;
  width: 100%;
}
.SectionTwo .left {
  padding: 20px;
}
.SectionTwo .left .heading {
  font-weight: 400;
  letter-spacing: 2px;
}
.SectionTwo .left span::before {
  background-image: url(../../assets/images/stroke.png);
  stroke: red;
  stroke-width: 0.5rem;
  fill: transparent;
  top: 50%;
}
.SectionTwo .left .paragraph {
  margin-top: 30px;
}
.SectionTwo .left .list_items {
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
}
.SectionTwo .left .list_items .list_item {
  color: white;
  margin-top: 2rem;
}
.SectionTwo .left .list_items .list_item .icon {
  background: #062241;
  width: -moz-fit-content;
  width: fit-content;
  height: 55px;
  width: 55px;
  display: grid;
  place-content: center;
  font-size: 1.6rem;
  color: #09aff4;
  border-radius: 5px;
}
.SectionTwo .left .list_items .list_item h3 {
  font-size: 1.3rem;
  text-transform: capitalize;
  font-weight: 500;
  margin: 10px 0;
}
.SectionTwo .left .list_items .list_item .paragraph {
  margin-top: 0;
  line-height: 1.5rem;
  font-size: 1rem;
  width: 70%;
}
.SectionTwo .left .btn_container {
  margin-top: 2rem;
}
.SectionTwo .right {
  display: grid;
  place-content: center;
  padding: 30px;
  height: 100vh;
  position: relative;
}
.SectionTwo .right .image_container {
  display: grid;
  place-content: center;
  height: 100%;
  width: 100%;
  margin: auto;
  position: absolute;
}
.SectionTwo .right .image_container img {
  height: 320px;
}

@media (min-width: 550px) {
  .SectionTwo .right .image_container img {
    height: 500px;
    width: 500px;
  }
  .SectionTwo .left {
    padding: 50px;
  }
}
@media (min-width: 768px) {
  .SectionTwo .right {
    height: 100vh;
  }
  .SectionTwo .right .image_container img {
    height: 650px;
    width: 650px;
  }
  .SectionTwo .left {
    padding: 30px;
    padding-top: 0px;
    margin-top: -30px;
  }
  .SectionTwo .left .list_items .list_item {
    margin-top: 4rem;
    display: flex;
    max-width: 600px;
  }
  .SectionTwo .left .list_items .list_item div {
    margin-top: -12px;
    margin-left: 1rem;
  }
  .SectionTwo .left .list_items .list_item div margin h3 {
    margin: 0 0;
  }
  .SectionTwo .left .list_items .list_item .paragraph {
    width: 90%;
  }
  .SectionTwo .left .btn_container {
    margin-top: 5rem;
  }
}
@media (min-width: 980px) {
  .SectionTwo .left,
  .SectionTwo .right {
    width: 50%;
  }
  .SectionTwo .left {
    padding: 15px;
  }
  .SectionTwo .left .heading {
    font-size: 3rem;
    font-weight: 500;
  }
  .SectionTwo .left .list_items .list_item .paragraph {
    width: 50%;
  }
  .SectionTwo .right .image_container {
    margin-top: -8rem;
  }
  .SectionTwo .right .image_container img {
    height: 450px;
    width: 450px;
  }
}
@media (min-width: 1024px) {
  .SectionTwo {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 1200px) {
  .SectionTwo {
    align-items: center;
    justify-content: center;
  }
  .SectionTwo .left {
    padding-left: 40px;
  }
  .SectionTwo .left .list_items {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 5px;
    grid-row-gap: 0px;
  }
  .SectionTwo .left .list_items .list_item .icon {
    flex-shrink: 0;
  }
  .SectionTwo .left .list_items .list_item .paragraph {
    width: 100%;
  }
  .SectionTwo .right .image_container {
    margin-top: 0rem;
  }
}
@media (min-width: 1400px) {
  .SectionTwo {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .SectionTwo .right .image_container img {
    height: 600px;
    width: 600px;
  }
}/*# sourceMappingURL=SectionTwo.css.map */