.Homepage {
  height: 130vh;
  background: #080f2f;
  position: relative;
  padding-top: 1px;
}
.Homepage .overlay {
  opacity: 0.2;
  background-image: url(../assets/images/section_one_background.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 0;
}
.Homepage .section_one {
  width: 100%;
  height: 100%;
  z-index: 2;
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
}
.Homepage .section_one::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  width: 100%;
  height: 100%;
  -webkit-clip-path: polygon(50% 100%, 100% 0%, 100% 100%, 0 100%);
          clip-path: polygon(50% 100%, 100% 0%, 100% 100%, 0 100%);
  background: rgba(72, 32, 167, 0.5);
  z-index: -1;
}
.Homepage .section_one .content,
.Homepage .section_one .right {
  width: 100%;
  height: 50%;
  position: relative;
}
.Homepage .section_one .content {
  margin: 8.5rem auto 0 auto;
}
.Homepage .section_one .content .heading span::before {
  background-image: url(../assets/images/curly.png);
}
.Homepage .section_one .content .socials {
  display: flex;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.Homepage .section_one .content .socials .icons {
  height: 40px;
  width: 40px;
  display: grid;
  color: white;
  cursor: pointer;
  margin-right: 4px;
  font-size: 1.1rem;
  position: relative;
}
.Homepage .section_one .content .socials .icons a {
  color: white;
}
.Homepage .section_one .content .socials .icons i {
  position: absolute;
  transition: all 0.2s ease;
  top: 0;
}
.Homepage .section_one .content .socials .icons:hover i {
  color: #09aff4;
  top: -20%;
}
.Homepage .section_one .content .paragraph {
  color: white;
  opacity: 0.75;
  margin-top: 2rem;
  width: 80%;
}
.Homepage .section_one .content .btn_container {
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  margin-top: 2rem;
}
@media (min-width: 981px) {
  .Homepage .section_one .content .paragraph {
    margin-top: 0rem;
  }
}
.Homepage .section_one .right .image_container {
  height: 100%;
  width: 100%;
  display: grid;
  justify-content: center;
}
.Homepage .section_one .right .image_container img {
  margin-top: -2.5rem;
  height: 320px;
  will-change: transform;
}
@media (min-width: 576px) {
  .Homepage .section_one .content {
    max-width: 520px;
  }
  .Homepage .section_one .content .heading {
    font-size: 3rem;
  }
}
@media (min-width: 768px) {
  .Homepage .section_one .content {
    max-width: 700px;
  }
  .Homepage .section_one .right .image_container img {
    margin-top: 2rem;
    height: 700px;
  }
}
@media (min-width: 981px) {
  .Homepage .section_one .content,
  .Homepage .section_one .right {
    width: 50%;
    height: 50vh;
    max-width: 650px;
  }
}
@media (min-width: 981px) {
  .Homepage .section_one {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 2.5rem;
  }
  .Homepage .section_one .content {
    margin: 0;
  }
  .Homepage .section_one .content .heading {
    margin-bottom: 1rem;
  }
  .Homepage .section_one .right {
    margin-right: 2.5rem;
  }
}
@media (max-width: 981px) {
  .Homepage {
    padding-top: 1px;
  }
  .Homepage .section_one::after {
    -webkit-clip-path: polygon(0 100%, 100% 50%, 100% 100%);
            clip-path: polygon(0 100%, 100% 50%, 100% 100%);
  }
}

@media (min-width: 375px) {
  .Homepage .section_one .right .image_container img {
    height: 350px;
    margin-top: -1rem;
  }
}
@media (min-width: 500px) {
  .Homepage .section_one .right .image_container img {
    height: 480px;
    margin-top: -5rem;
  }
}
@media (min-width: 981px) {
  .Homepage {
    height: 100vh;
  }
  .Homepage .section_one {
    margin-top: 5rem;
  }
  .Homepage .section_one::after {
    bottom: 80px;
  }
  .Homepage .section_one .content .paragraph {
    width: 70%;
    margin-top: 2rem;
    font-size: 1.13rem;
  }
  .Homepage .section_one .content .btn_container button:first-child {
    padding: 0.75rem 2rem;
  }
  .Homepage .section_one .right .image_container img {
    height: 450px;
    margin-top: 2rem;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1100px) {
  .Homepage {
    height: 100vh;
  }
  .Homepage .section_one .content,
  .Homepage .section_one .right {
    margin-top: -16rem;
  }
  .Homepage .section_one .content .heading {
    font-size: 2.3rem;
  }
  .Homepage .section_one .content .paragraph {
    font-size: 1rem;
  }
}
@media (min-width: 1200px) {
  .Homepage {
    height: 100vh;
  }
  .Homepage .section_one .content,
  .Homepage .section_one .right {
    margin-top: -16rem;
  }
  .Homepage .section_one .content .heading {
    font-size: 3.5rem;
  }
  .Homepage .section_one .content .paragraph {
    font-size: 1.2rem;
    margin-top: 2rem;
    width: 100%;
  }
  .Homepage .section_one .content .btn_container button:first-child {
    padding: 0.8rem 2.75rem;
  }
  .Homepage .section_one .right .image_container img {
    height: 560px;
    margin-top: -5.5rem;
  }
}
@media (min-width: 1400px) {
  .Homepage .section_one .content,
  .Homepage .section_one .right {
    margin-top: -6rem;
  }
}/*# sourceMappingURL=Homepage.css.map */