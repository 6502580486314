.Footer {
  color: white;
  margin: auto;
  position: relative;
}
.Footer .container {
  position: relative;
  width: 100%;
  height: 100%;
}
.Footer .container .background,
.Footer .container .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
}
.Footer .container .background {
  background-repeat: no-repeat;
  background-image: url("../../assets/images/footer_bg.jpg");
  background-size: cover;
  background-position: center;
}
.Footer .container .overlay {
  opacity: 0.75;
  background: #060922;
}
.Footer .container .content {
  margin: auto;
  position: relative;
  z-index: 1;
  padding-bottom: 1rem;
  padding: 10px;
  padding-top: 5rem !important;
}
.Footer .details .icon img {
  height: 30px;
}
.Footer .details .paragraph {
  margin-top: 1.6rem;
  font-weight: 500;
  font-size: 1rem;
  opacity: 0.9;
  letter-spacing: 0.2px;
}
.Footer .details .news_letter {
  margin-top: 1.5rem;
}
.Footer .details .news_letter p {
  font-weight: 400;
  color: white !important;
  opacity: 1;
  font-size: 1.1rem;
  text-transform: capitalize;
}
.Footer .details .news_letter form {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  height: 50px;
  position: relative;
  overflow: visible;
}
.Footer .details .news_letter form input {
  font-size: 1.1rem;
  width: 100%;
  padding: 0.75rem 1.5rem;
  outline: none !important;
  border: none;
  color: white;
  background: rgba(255, 255, 255, 0.35);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  margin-bottom: 0;
  text-align: left;
  padding: 0.75rem;
  border-radius: 0.25rem;
  flex-grow: 1;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.Footer .details .news_letter form input::-moz-placeholder {
  color: white;
}
.Footer .details .news_letter form input::placeholder {
  color: white;
}
.Footer .details .news_letter form button {
  height: 100%;
  background: #09aff4;
  border: none;
  padding: 0.75rem;
  font-size: 1rem;
  font-weight: 600;
  color: white;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  letter-spacing: 1px;
}
.Footer .details .news_letter form::before {
  content: "* We Will Not Share Your Personal Info";
  color: white;
  opacity: 0.5;
  position: absolute;
  top: 100%;
  height: -moz-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: fit-content;
  z-index: 1;
  font-size: 0.9rem;
}
.Footer .location {
  margin-top: 3rem;
}
.Footer .location iframe {
  height: 100%;
  width: 100%;
  height: 300px;
  border: none;
}
.Footer .contact_info {
  margin-top: 3rem;
}
.Footer .contact_info label {
  display: flex;
  margin-top: 2rem;
}
.Footer .contact_info label i {
  font-size: 1.5rem;
  margin-right: 1rem;
  color: #09aff4;
}
.Footer .contact_info label a {
  color: white;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 500;
}
.Footer .details,
.Footer .location,
.Footer .contact_info {
  max-width: 500px;
}
.Footer .contact_info .socials {
  display: flex;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.Footer .contact_info .socials .icons {
  height: 40px;
  width: 40px;
  display: grid;
  color: white;
  cursor: pointer;
  margin-right: 4px;
  font-size: 1.1rem;
  position: relative;
  transition: all 0.5s;
}
.Footer .contact_info .socials .icons a {
  color: white;
}
.Footer .contact_info .socials .icons i {
  position: absolute;
  transition: all 0.2s ease;
  top: 0;
}
.Footer .contact_info .socials .icons:hover i {
  color: #09aff4;
  top: -20%;
}
.Footer .bottom {
  position: absolute;
  height: -moz-fit-content;
  height: fit-content;
  width: 95%;
  bottom: 10px;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 0.8rem;
}
.Footer .bottom a {
  color: white;
  text-decoration: none;
}
.Footer .bottom p {
  display: inline-block;
}

@media (min-width: 576px) {
  .Footer .container .content {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .Footer .container .content {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .Footer .container .content {
    max-width: 960px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }
  .Footer .location,
  .Footer .contact_info {
    margin-top: 0;
  }
}
@media (min-width: 1200px) {
  .Footer .container .content {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .Footer .container .content {
    max-width: 1320px;
  }
}/*# sourceMappingURL=Footer.css.map */