.TrustedBy {
  background: #09aff4;
  margin-top: 5rem;
  .container {
    padding: 2.5rem;
    .content {
      margin: auto;
      .heading {
        font-size: 1.75rem;
        opacity: 0.35;
        text-transform: capitalize;
        font-weight: 500;
        text-align: center;
        letter-spacing: 1px;
        margin-bottom: 3rem;
      }
    }
  }
  .swiper {
    height: 120px;
    .swiper-slide {
      border-radius: 5px;
      .img_container {
        display: grid;
        place-content: center;
        height: 100%;
        width: 100%;
        img {
          height: 100% !important;
          object-fit: contain;
          filter: grayscale(100%); /* Start as black and white */
          transition: filter 0.5s ease; /* Smooth transition effect */
        }
        &:hover img {
          filter: grayscale(
            0%
          ); /* On hover, remove grayscale to make it colorful */
        }
      }
    }
  }
}

@media (min-width: 576px) {
  .TrustedBy {
    .container {
      .content {
        max-width: 540px;
      }
    }
  }
}
@media (min-width: 768px) {
  .TrustedBy {
    .container {
      padding-top: 5rem;
      padding-bottom: 5rem;
      .content {
        max-width: 720px;
      }
    }
  }
}
@media (min-width: 992px) {
  .TrustedBy {
    .container {
      .content {
        max-width: 960px;
      }
    }
  }
}
@media (min-width: 1200px) {
  .TrustedBy {
    .container {
      .content {
        max-width: 1140px;
      }
    }
  }
}
@media (min-width: 1400px) {
  .TrustedBy {
    .container {
      .content {
        max-width: 1320px;
      }
    }
  }
}
