.Services {
  margin-top: 135vh;
  min-height: 100vh;
  .content {
    max-width: 540px;
    margin: auto;
    padding: 10px;
    .headline {
      position: relative;
    }
    .heading {
      font-weight: 500;
      letter-spacing: 1px;
      font-size: 2.4rem;
    }
    .paragraph {
      width: 80%;
    }
    .style_btn {
      margin-top: 1rem;
      padding-left: 2rem;
      &:hover {
        i {
          left: 30%;
        }
      }
      span {
        display: inline-block;
        height: 2rem;
        position: relative;
        width: 50px;
        i {
          top: 2px;
          left: 0%;
          position: absolute;
          margin-top: 7px !important;
          margin-left: 5px;
          transition: all 0.5s ease;
        }
      }
    }
  }
  .grid {
    .box {
      width: 100%;
      min-height: 300px;
      border: 1px solid #09aef480;
      border-radius: 10px;
      padding: 30px;
      margin-top: 2rem;
      position: relative;
      overflow: hidden;
      opacity: 1;

      &:hover .redirect_btn {
        color: white;
        transition: all 0.5s ease;
      }
      .before {
        height: 100%;
        width: 100%;
        background: #09aef4;
        left: -100%;
        top: 0;
        position: absolute;
        content: "";
        z-index: -1;
      }

      .icon {
        height: 60px;
        width: 60px;
        display: grid;
        place-content: center;
        font-size: 3rem;
        border-radius: 5px;
        img {
          height: 50px;
        }
      }
      .title {
        font-size: 1.4rem;
        font-weight: 600;
        letter-spacing: 1px;
        color: white;
        margin-top: 1rem;
      }
      .paragraph {
        width: 100%;
        margin-top: 1rem;
      }
      .redirect_btn {
        color: #09aef4;
        display: flex;
        align-items: center;
        margin-top: 1rem;
        font-weight: 600;
        cursor: pointer;
        background: none;
        border: none;
        font-size: 1rem;
        span {
          display: inline-block;
          height: 2rem;
          position: relative;
          width: 50px;
          margin-left: 0.5rem;
          i {
            top: 2px;
            left: 0%;
            position: absolute;
            margin-top: 7px !important;
            margin-left: 5px;
            transition: all 0.5s ease;
          }
        }
        &:hover {
          i {
            left: 30%;
          }
        }
      }
    }
  }
}
@media (min-width: 768px) {
  .Services {
    .content {
      max-width: 720px;
      .grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        // padding: 20px;
        .box {
          width: calc(50% - 10px);

          .paragraph {
            width: 100%;
          }
        }
      }
    }
  }
}
@media (min-width: 992px) {
  .Services {
    margin-top: 105vh;
    .content {
      max-width: 960px;
      .grid {
        .box {
          width: calc(33% - 20px);
        }
      }
      .headline {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 0;
      }
      .style_btn {
        flex-grow: 0;
        white-space: nowrap;
      }
    }
  }
}
@media (min-width: 1200px) {
  .Services {
    .content {
      max-width: 1140px;
    }
  }
}
// @media only screen and (min-width: 1000px) and (max-width: 1100px) {
//   .Services {
//   }
// }
@media (min-width: 1400px) {
  .Services {
    .content {
      max-width: 1320px;
    }
  }
}
