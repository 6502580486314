.AboutUs .container {
  position: relative;
  color: white;
}
.AboutUs .container a {
  text-decoration: none;
  color: inherit;
}
.AboutUs .container .content {
  margin: auto;
  padding: 10px;
}
.AboutUs .container .content .heading {
  font-weight: 500;
}
.AboutUs .container .content .grid_container {
  margin: 5rem auto;
}
.AboutUs .container .content .grid {
  margin-top: 2rem;
}
.AboutUs .container .content .grid .image,
.AboutUs .container .content .grid .para {
  width: 100%;
  height: 350px;
}
.AboutUs .container .content .grid .image {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}
.AboutUs .container .content .grid .image img {
  -o-object-position: center;
     object-position: center;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  margin: auto;
}
.AboutUs .container .content .grid .para {
  font-size: 1.2rem;
  min-height: 350px;
  height: 100%;
  text-align: justify;
}

@media (min-width: 576px) {
  .AboutUs .container .content {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .AboutUs .container .content {
    max-width: 720px;
  }
  .AboutUs .container .content .grid .image {
    margin-bottom: 3rem;
  }
}
@media (min-width: 992px) {
  .AboutUs .container .content {
    max-width: 960px;
  }
  .AboutUs .container .content .grid {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .AboutUs .container .content .grid image {
    margin-bottom: 0;
  }
  .AboutUs .container .content .grid .image,
  .AboutUs .container .content .grid .para {
    width: 49%;
  }
  .AboutUs .container .content .grid_container:nth-child(odd) .grid {
    flex-direction: row-reverse;
  }
}
@media (min-width: 1200px) {
  .AboutUs .container .content {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .AboutUs .container .content {
    max-width: 1320px;
  }
}/*# sourceMappingURL=AboutUs.css.map */